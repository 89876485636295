import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from "../hooks/useProfile";

export const ProfilePage = () => {
  const { userId } = useParams();
  const profile = useProfile(userId ?? "");
  const navigate = useNavigate();

  // Ensure we scroll to the top of the page when we navigate to a new profile
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToChat = React.useCallback(() => {
    navigate(`/profiles/${userId}/chat`);
  }, [userId, navigate]);

  return (
    <>
      <div className="flex flex-row bg-white m-0 w-100">
        <div className="w-100 h-96 overflow-clip">
          <img
            className="object-cover w-[100vw]"
            src={`${profile?.avatar}`}
            alt="Random profile pic. Probably a selfie"
          />
        </div>
      </div>
      <div className="bg-black h-screen w-full text-white p-3">
        <div className="flex flex-row justify-between ">
          <div className="text-3xl p-2 font-bold">{profile?.name}</div>
          <div
            className="text-3xl p-2 font-bold bg-orange-900 rounded-full cursor-pointer"
            onClick={goToChat}
          >
            Chat 💬
          </div>
        </div>
        <div className="text-1xl p-2 font-bold">
          {(Number(profile?.id) ?? 0) / 10}mi
        </div>
        <p className="mt-4 border-t-2 pt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Semper
          quis lectus nulla at volutpat. Justo laoreet sit amet cursus sit amet
          dictum.
        </p>
      </div>
    </>
  );
};
