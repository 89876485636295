import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ProfileBrowser } from "./components/ProfileBrowser";
import { ProfilePage } from "./components/ProfilePage";
import { ChatView } from "./components/ChatView";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<ProfileBrowser />} />
        <Route path="profiles/:userId" element={<ProfilePage />} />
      </Route>
      <Route path="profiles/:userId/chat" element={<ChatView />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
