import * as React from "react";
import { useProfiles } from "../hooks/useProfiles";
import { Profile } from "./Profile";
export const ProfileBrowser = () => {
  const profiles = useProfiles();

  const shuffledProfiles = React.useMemo(() => {
    return profiles.sort(() => -1 /* Math.random() - 0.5 */);
  }, [profiles]);

  return (
    <div className="bg-slate-800 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
      <div className="flex flex-row flex-wrap justify-around">
        {shuffledProfiles.map((profile) => (
          <Profile key={profile.id} profile={profile} />
        ))}
      </div>
    </div>
  );
};
