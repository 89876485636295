import * as React from "react";
import { decode } from "blurhash";
import { useNavigate } from "react-router-dom";

export const Profile: React.FC<{
  profile: {
    id: number;
    name: string;
    avatar: string;
  };
}> = ({ profile }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();

  React.useLayoutEffect(() => {
    const img = new Image();
    img.src = profile.avatar;
    img.onload = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;
    const pixels = decode(
      "LEHV6nWB2yk8pyo0adR*.7kCMdnj",
      canvasRef.current.width,
      canvasRef.current.height
    );
    const imageData = ctx.createImageData(
      canvasRef.current.width,
      canvasRef.current.height
    );
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
  }, []);

  return (
    <>
      <div
        key={profile.id}
        className="flex flex-col items-center w-[25vw] mb-10 text-white rounded-xl overflow-hidden shadow-md"
        onClick={() => {
          navigate("/profiles/" + profile.id);
        }}
      >
        <canvas className="w-[25vw] h-[25vw] object-cover" ref={canvasRef} />
        <div className="w-full flex flex-col bg-slate-900">
          <div className="justify-centerw-full text-center ml-0 p-1">
            <h2 className="text-l font-semibold">{profile.name}</h2>
          </div>
          <div className="w-full flex flex-row justify-between px-2 pb-3">
            <div>{26 + profile.id}</div>
            <div>{Number((1.3 + profile.id / 20).toFixed(1))}mi</div>
          </div>
        </div>
      </div>
    </>
  );
};
