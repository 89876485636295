import * as React from "react";

const JPGS = [1, 2, 5];

const NAMES = [
  "Bungry",
  "Hunry4You",
  "Looking 👀",
  "Bear4Twink",
  "💦💦💦",
  "Roy",
  "^ sissy ^",
  "Daddy",
  "Tony 🕳️",
  "Holes_4_U",
  "🍑",
  "↓↓↓",
  "CB",
  "Alex",
  "Giant🐓",
  "😈",
  "🍆",
  "Horny",
  "Hey",
  "Bob",
  "💦 now",
  "HungStud33",
];

export const useProfiles = () => {
  return React.useMemo(() => {
    return NAMES.map((name, i) => {
      const id = i + 1;
      const avatarExt = JPGS.includes(id) ? "jpg" : "png";
      const imgFileName = id.toString().padStart(2, "0");
      const avatar = `${process.env.PUBLIC_URL}/profiles/${imgFileName}.${avatarExt}`;
      return {
        id,
        name,
        avatar,
      };
    }).sort(() => Math.random() - 0.5);
  }, []);
};
