import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

function App({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation();
  const hasBack = location.pathname !== "/";

  const navigate = useNavigate();
  const goBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);
  return (
    <>
      <div className="flex flex-row py-4 top-0 sticky justify-center items-center width-100 bg-black text-white">
        {hasBack && (
          <div
            onClick={goBack}
            className="flex justify-center align-center text-3xl text-white absolute left-4 w-10 h-10 bg-slate-900 rounded-full cursor-pointer pt-2"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="arrow-left"
              className="w-3/4 h-3/4 align-middle text-white"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
              ></path>
            </svg>
          </div>
        )}
        <img
          className="w-16 h-16 mr-2"
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="GloryMate Logo"
        />
        <h1 className="text-3xl font-semibold text-center text-white">
          GloryMate
        </h1>
      </div>
      <Outlet />
    </>
  );
}

export default App;
