import "@chatscope/chat-ui-kit-styles/dist/default/styles.css";
import "./ChatView.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  ConversationHeader,
  TypingIndicator,
  MessageModel,
} from "@chatscope/chat-ui-kit-react";
import { useProfile } from "../hooks/useProfile";
import { useParams } from "react-router-dom";
import React from "react";

export const ChatView = () => {
  const { userId } = useParams();
  const profile = useProfile(userId ?? "");

  const [messages, setMessages] = React.useState<MessageModel[]>([
    {
      message: "Hi Sexy! Up to anything later tonight?",
      sentTime: "just now",
      sender: profile?.name,
      direction: "incoming",
      position: "single",
    },
    {
      message: "maybe, but a little shy here",
      sentTime: "just now",
      sender: "me",
      direction: "outgoing",
      position: "single",
    },
    {
      message:
        "no biggie, into Glory Holes? I am hosting. You can still be shy here, no judgment.",
      sentTime: "just now",
      sender: "me",
      direction: "incoming",
      position: "single",
    },
  ]);

  return (
    <div className="w-100 h-screen flex flex-col">
      <ConversationHeader>
        <ConversationHeader.Back
          onClick={() => {
            window.history.back();
          }}
        />
        <Avatar src={profile?.avatar} name={profile?.name} />
        <ConversationHeader.Content
          userName={profile?.name}
          info="Active 10 mins ago"
        />
      </ConversationHeader>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((message, index) => (
              <Message model={message} key={index} />
            ))}
            {/* <TypingIndicator /> */}
          </MessageList>
          <MessageInput
            onSend={(value) => {
              setMessages((messages) => [
                ...messages,
                {
                  message: value,
                  sentTime: "just now",
                  sender: "me",
                  direction: "outgoing",
                  position: "single",
                },
              ]);
            }}
            placeholder={`Type your Glorious message to ${profile?.name}`}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};
